<template>
  <label :for="name" :class="[$style.checkbox, { [$style.disabled]: disabled }]">
    <input
      :id="name"
      v-model="localModelValue"
      type="checkbox"
      :disabled="disabled"
      :class="{ [$style.disabled]: disabled }"
    />
    <span><slot /></span>
  </label>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    name: string
    modelValue: boolean
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
)

const emit = defineEmits<{
  (e: "update:modelValue", v: boolean): void
}>()

const localModelValue = computed({
  get() {
    return props.modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})
</script>

<style scoped module lang="scss">
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  input[type="checkbox"] {
    // デフォルトのスタイルを消す
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    padding: 0;
    margin-right: 0.5rem;
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid;
    border-radius: 0.25rem;
    border-color: $primary-20;
    bottom: 1px;

    &:checked {
      border-color: $primary;
      background-color: $primary;
    }

    // チェックマークを生成
    &:checked:before {
      position: absolute;
      top: 0.1875rem;
      left: 0.375rem;
      transform: rotate(50deg);
      width: 0.375rem;
      height: 0.625rem;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      content: "";
    }

    &:disabled {
      cursor: not-allowed;
      border-color: $black-20;
      color: $black-20;
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: $black-20;
  }
}
</style>
